import { BreadcrumbsArrayItem } from '@components/breadcrumbs';
import {
  IMarketInsightFilterForm,
  MarketInsightForm,
} from '@src/types/marketInsight';

export const MARKET_INSIGHT_NAVLINK_ARRAY: Record<
  string,
  BreadcrumbsArrayItem[]
> = {
  MAIN_PAGE: [
    {
      linkUrl: '',
      linkValue: 'Program & Campaign',
    },
    {
      linkUrl: '/market-insight',
      linkValue: 'Market Insight',
    },
  ],
};

export const MARKET_INSIGHT_NAV_TOOLTIP = {
  MAIN: 'Menu to manage VIP user’s Market Insight articles. This includes adding, scheduling, hiding & showing, and editing in-app Market Insights articles.',
};

export enum MARKET_INSIGHT_STATUS {
  SCHEDULED = 'Scheduled',
  SHOWN = 'Shown',
  HIDDEN = 'Hidden',
  ALL = 'All',
}

export enum MARKET_INSIGHT_FORM_FIELD {
  TITLE_EN = 'titleEn',
  TITLE_ID = 'titleId',
  DESC_EN = 'descEn',
  DESC_ID = 'descId',
  DATE_TIME = 'scheduledAt',
  THUMBNAIL_EN = 'thumbnailEn',
  THUMBNAIL_EN_SRC = 'thumbnailEnSrc',
  ARTICLE_EN = 'articleEn',
  ARTICLE_EN_SRC = 'articleEnSrc',
  TOGGLE_SCHEDULED = 'toggleSchedule',
}

export const FILTER_STATUS_OPTIONS = [
  { key: 'SCHEDULED', value: MARKET_INSIGHT_STATUS.SCHEDULED },
  { key: 'SHOWN', value: MARKET_INSIGHT_STATUS.SHOWN },
  { key: 'HIDDEN', value: MARKET_INSIGHT_STATUS.HIDDEN },
  { key: 'ALL', value: MARKET_INSIGHT_STATUS.ALL },
];

export const initialMarketInsightStateForm: MarketInsightForm = {
  titleEn: '',
  titleId: '',
  descEn: '',
  descId: '',
  scheduledAt: undefined,
  thumbnailEn: '',
  thumbnailEnSrc: '',
  articleEn: '',
  articleEnSrc: '',
  toggleSchedule: false,
};

export enum ERR_MSG_MI {
  REQUIRED = 'Field is required',
  DATE_REQUIRED = 'Field is required with correct format',
  DATE_PAST = 'Cannot schedule past times',
}

export const FILE_RESOLUTION = {
  THUMBNAIL: {
    type: 'image',
    sizeInKB: 600,
    ratioWidth: 16,
    ratioHeight: 9,
  },
  ARTICLE: {
    type: 'pdf',
    sizeInKB: 2000,
  },
};

export enum MARKET_INSIGHT_FILTER_FIELD {
  STATUS = 'status',
  DATE_TO = 'toDate',
  DATE_FROM = 'fromDate',
}

export const initialFilterForm: IMarketInsightFilterForm = {
  fromDate: null,
  toDate: null,
  status: 'ALL',
};
